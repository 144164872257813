nav {
  display: flex;
  padding: 1em;
  justify-content: space-around;
  flex-wrap: wrap;
  background: #007220;
  font-weight: 600;
}

nav img {
  /* border: 1px solid blue; */
}
.nav-main{
  display: flex;
  padding: 1em;
  justify-content: space-around;
  flex-wrap: wrap;
  background: #007220;
  font-weight: 600;

}

.nav__projectName {
  color: #e4f9ff;
  text-decoration: none;
}

.authLink {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.authLink + .authLink {
  padding: 1em;
}

.authLink:hover {
  color: #ffffff;
}

.nav-logoutbtn {
  font-family: inherit;
  font-weight: inherit;
  padding: 0.5em 1em;
  margin-left: 1em;
  background-color: #007220;
  /* box-shadow: 0ffffff 0 8px 0 aliceblue; */
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  position: sticky;
  top: 0;
}

.nav-logoutbtn:hover {
  transform: scale(1.05);
  background-color: #ffffff;
  color:#007220;
}